import { requiredRole } from '~/services/database/permissions'
import { getUserToken } from '~/services/supabase'
import { translations } from '~/translations'
import { Rights } from '~/types/account/rights'
import { APIResponse } from '~/types/api/response'
import { Job } from '~/types/comic/chapter'

const getAccountRights = async () => {
  const token = await getUserToken()
  const rights: Rights[] = []

  if (token) {
    const data = await fetch('/api/subscription/rights', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      }
    })

    const response: APIResponse<Rights[]> = await data.json()
    if (response.error) {
      const error = new Error(response.error.message)
      reportError(error)
      throw error
    }

    rights.push(...response.result)
  }

  return rights
}

const canAccessFeature = async (job: Job) => {
  let canAccess = false
  const rights = await getAccountRights()

  // Duo can access every feature
  if(rights.includes('duo')) return true

  // Otherwise, group by feature
  const role = requiredRole(job)
  if((role === 'translation' || role == 'proofreading') && rights.includes('translation')) canAccess = true
  if((role === 'typesetting' && rights.includes('typesetting'))) canAccess = true

  return canAccess
}

const requiredSubcription = (job: Job) => {
  let label = ''

  const role = requiredRole(job)
  if ((role === 'translation' || role === 'proofreading')) label = translations().subscriptions.plans.linguist
  if ((role === 'typesetting')) label = translations().subscriptions.plans.graphist

  return label
}

export {
  getAccountRights,
  canAccessFeature,
  requiredSubcription
}