import { translations } from '~/translations'

export const roles = [
  {
    value: 'admin',
    label: translations().user.role.admin,
    jobs: ['translation', 'proofreading', 'typesetting', 'typeProofreading', 'validation', 'completed']
  },
  {
    value: 'translation',
    label: translations().user.role.translation,
    jobs: ['translation']
  },
  {
    value: 'proofreading',
    label: translations().user.role.proofreading,
    jobs: ['proofreading', 'typeProofreading']
  },
  {
    value: 'typesetting',
    label: translations().user.role.typesetting,
    jobs: ['typesetting']
  }
] as const

export type RoleType = (typeof roles[number])['value']